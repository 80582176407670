<template>
  <div class="orders">
    <h2>{{ content.orders.title }}</h2>
    <v-data-table
      :headers="headers"
      :items="dados"
      :footer-props="{ itemsPerPageText: content.orders.table }"
    >
      <template v-slot:top>
        <v-toolbar flat color="transparent">
          <v-dialog v-model="dialog" overlay-opacity="0.95" max-width="500px">
            <v-card>
              <a class="close" v-on:click="dialog = false"
                ><span class="icomoon icon-icon-close"></span
              ></a>
              <v-card-text>
                <div class="wines">
                  <h2>{{ content.orders.subtitle }}</h2>
                  <ul>
                    <li v-for="(wine, i) in wines" :key="i">
                      <div class="image">
                        <img :src="img_url + wine.img" />
                      </div>
                      <div class="wine-info">
                        <p class="name">{{ wine.name }}</p>
                        <p>{{ wine.year }} | {{ wine.type }}</p>
                        <p class="one-line">
                          {{ content.cart.qt }}<span>{{ wine.quantity }} <span class="quantity" v-if="wine.box_quantity < 2 && wine.quantity == 1">{{content.product.bottle}}</span><span class="quantity" v-if="wine.box_quantity > 1 && wine.quantity == 1">{{content.product.box}} ({{wine.box_quantity}} x {{wine.it}} cl)</span><span class="quantity" v-if="wine.box_quantity < 2 && wine.quantity > 1">{{content.product.bottles}}</span><span class="quantity" v-if="wine.box_quantity > 1 && wine.quantity > 1">{{content.product.boxes}} ({{wine.box_quantity}} x {{wine.it}} cl)</span></span>
                        </p>
                        <p class="one-line">
                          {{ (wine.price * wine.quantity).toFixed(2) }} CHF
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <a @click="details(item)" class="details-list mr-8"
          ><span class="icomoon icon-icon-list"></span
        ></a>
        <a @click="orderAgain(item)" small class="button-fill">
          {{ content.orders.btn }}
        </a>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog2" width="500">
      <v-card class="mx-auto dialog-card">
        <h4 v-html="content.dialogs.cart.title"></h4>
        <p v-html="content.dialogs.cart.description"></p>
        <a v-on:click="dialog2 = false"
          ><span class="icon-icon-close"></span
        ></a>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";

export default {
  mixins: [computedMixins, methodsMixins],
  data() {
    return {
      dialog: false,
      dialog2: false,
      items: [],
      orders: [
        {
          id: "1111",
          dt_created: "2008.08.20",
          items_cost: "50.00",
          articles: "2",
          order_status: "received",
        },
      ],
      wines: [
        {
          name: "Quinta da Fata",
          image: "garrafa2.png",
          year: "2014",
          type: "red",
          price: 100.0,
          quantity: 2,
        },
        {
          name: "Humilitas Reserve",
          image: "garrafa1.png",
          year: "2015",
          type: "red",
          price: 150.0,
          quantity: 1,
        },
      ],

      initializeEP: "orders/user-list/",
      n: 0,
    };
  },
  mounted() {
    this.initialize(
      this.initializeEP + window.localStorage.getItem("user_id")
    );
  },
  computed: {
    headers() {
      let headers = [
        { text: this.content.orders.id, value: "id", align: "center" },
        {
          text: this.content.orders.date,
          value: "dt_created",
          align: "center",
        },
        {
          text: this.content.orders.price,
          value: "items_cost",
          align: "center",
        },
        {
          text: this.content.orders.articles,
          value: "articles",
          align: "center",
        },
        {
          text: this.content.orders.status,
          value: "order_status",
          align: "center",
        },
        {
          text: this.content.orders.delivery,
          value: "delivery_date",
          align: "center",
        },
        { text: this.content.orders.details, value: "actions", sortable: false, align: "left" },
      ];
      return headers;
    },
    materiaisCarrinho() {
      this.n;
      let temp = JSON.parse(window.localStorage.getItem("cart")) || [];

      return temp;
    },
  },
  methods: {
    async orderAgain(item) {
      this.n += 1;
      if (this.materiaisCarrinho.length == 0) {
        window.localStorage.setItem("cart", item.order_items);
        this.$emit("update-cart");

        this.$emit("update-modal", "cart");
      } else if (
        confirm("Are you sure you want overwrite the items in your cart?")
      ) {
        window.localStorage.setItem("cart", item.order_items);
        this.$emit("update-cart");

        this.$emit("update-modal", "cart");
      }
    },
    details(item) {
      this.wines = JSON.parse(item.order_items);
      this.dialog = true;
    },
  },
};
</script>


<style lang="scss">
@import "@/assets/scss/style.scss";

.orders {
  background-color: $light-greyII;
  padding: 80px 20px;
  min-height: calc(100vh - 330px);
  h2 {
    color: $dark-grey;
    margin-bottom: 40px;
  }
  .v-data-table {
    background-color: transparent;
    max-width: 1440px;
    margin: 0 auto;
    color: black;
    tr {
      height: 80px;
    }
    th {
      span {
        color: black !important;
        text-transform: uppercase;
        text-align: center;
      }
    }
    td:nth-last-child(1) {
      width: 380px;
      @include responsive(0, 600px) {
        width: 100%;
        text-align: center !important;
      }
    }
  }
}


.details-list {
  color: black!important;
  // width: 35px;
  // height: 35px;
  // display: inline-block;
  // border-radius: 50%;
  // margin-right: 10px;
  span {
    color: $black;
    line-height: 35px;
    font-size: 14px;
    margin-left:15px;
    margin-right:30px;
  }
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: $dark-grey !important;
  span {
    font-size: 14px !important;
  }
}

.button-fill {
  margin: 0 auto;
  display: inline-block;
  padding: 0 15px;
  @include responsive(0, 600px) {
    margin-bottom: 20px;
  }
}

.wines {
  h2 {
    color: $dark-grey;
    font-size: 22px;
    text-align: left;
    padding-top: 30px;
    font-family: $font2;
    letter-spacing: 2px;
    font-weight: 300;
    text-transform: uppercase;
  }
  ul {
    li {
      border-bottom: 1px solid black;
      margin-bottom: 20px;
      padding: 20px 0;
      position: relative;
      text-align: left;
      .delete {
        position: absolute;
        top: 10px;
        right: 0;
        span {
          font-family: "icomoon";
          font-size: 10px;
          color: $dark-grey;
          &:hover {
            color: $red;
          }
        }
      }
      .image {
        width: 20%;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        position: relative;
        height: 200px;
        img {
          height: 180px;
          position: absolute;
          top: 50%;
          left:50%;
          transform: translate(-50%, -50%);
        }
      }
      .wine-info {
        width: 80%;
        display: inline-block;
        padding-left: 30px;
        vertical-align: middle;
        @include responsive(0, 512px) {
          padding-left: 10px;
        }
        p {
          color: black;
          text-align: left;
          font-size: 17px;
          font-weight: 400;
          line-height: 25px;
          @include responsive(0, 512px) {
            font-size: 15px;
          }
          span {
            margin-left: 10px;
            @include responsive(0, 512px) {
              margin-left: 5px;
            }
          }
          &.name {
            font-family: $font1;
            font-size: 20px;
            margin-bottom: 5px;
            font-weight: 500;
          }
          a {
            color: black;
            font-weight: 600;
            font-size: 20px;
            margin: 0 5px;
            &:hover {
              color: $red;
            }
            @include responsive(0, 512px) {
              font-size: 18px;
            }
          }
        }
        .quantity{
          text-transform: none;
        }
        p:nth-child(2) {
          font-weight: 300;
        }
        p:nth-child(3) {
          text-transform: uppercase;
        }
        p:nth-child(4) {
          color: $red;
          font-weight: 600;
          width: 50%;
        }
      }
    }
  }
}

.dialog-card {
  text-align: center;
  padding: 80px !important;
  background-color: $red !important;
  h4 {
    text-align: center !important;
    margin-bottom: 15px !important;
    line-height: 30px;
    text-transform: none;
    color: white;
  }
  p {
    text-align: center !important;
    color: white;
    margin-bottom: 0;
    margin-top: 40px;
  }
  a {
    color: white !important;
    font-family: "icomoon";
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.v-data-table{
  header{
    display: none;;
  }
}

</style>